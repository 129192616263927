<template lang="">
  <div class="ComplaintList">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="dynamic_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" />
      <div class="strip">
        <div class="car">
          投诉建议
        </div>
      </div>
      <div class="hide">
      <div class="quest-list">
          <el-row :gutter="22">
            <el-col :span="12" v-for="item in load" :key="item.id">
              <div class="quest-item">
                <div class="quest-title clear">
                  <span class="circle ask">问</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                  >
                    {{ item.compReason }}
                  </router-link>
                </div>
                <div class="quest-title clear">
                  <span class="circle ans">答</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                    >{{ item.compReply }}</router-link
                  >
                </div>
              </div>
            </el-col>
          </el-row>
              <Pagi
                :total="pagi.total"
                :current="pagi.current"
                :sizes="pagi.size"
                @pagicurrent="togglePagi"/>
      </div>
      
      </div>
      <div class="dispy">
      <div class="quest-list">
          <el-row :gutter="22">
            <el-col :span="24" v-for="item in load" :key="item.id">
              <div class="quest-item">
                <div class="quest-title clear">
                  <span class="circle ask">问</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                  >
                    {{ item.compReason }}
                  </router-link>
                </div>
                <div class="quest-title clear">
                  <span class="circle ans">答</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                    >{{ item.compReply }}</router-link
                  >
                </div>
              </div>
            </el-col>
          </el-row>
              <Pagi
                :total="pagi.total"
                :current="pagi.current"
                :sizes="pagi.size"
                @pagicurrent="togglePagi"/>
      </div>
      
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import BannerImage from "@/components/main/BannerImage";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  components: {
    Pagi,
    Headers,
    Breadcrumb,
    Header,
    Footer,
    BannerImage,
  },
  created() {
    this.loadinfo(1);
  },
  data() {
    return {
      load: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      crumbdata: [{ name: "投诉与建议", url: "/ComplaintList" }],
    };
  },
  methods: {
    loadinfo(value) {
      getAction("/complaint/tourComplaint/openList", { pageNo: value }).then(
        (res) => {
          if (res.success) {
            this.load = res.result.records;
          }
        }
      );
    },
    togglePagi(value) {
      this.loadinfo(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.quest-list {
  min-height: 500px;
  .quest-item {
    background-color: #f4f4f4;
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 15px;
    margin-left: 18px;
    margin-right: 18px;
    .quest-title {
      height: 36px;
      margin-bottom: 20px;
      .circle {
        display: block;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #fffefe;
        font-size: 16px;
        border-radius: 50%;
        float: left;
        margin-right: 8px;
      }
      .text {
        float: left;
        width: 460px;
        color: #666666;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover {
          color: #6EB400;
        }
      }
      .ask {
        background-color: #6EB400;
      }
      .ans {
        background-color: #1aa4fb;
      }
    }
    .quest-sm-list {
      border-top: 1px dashed #999999;
      .quest-sm-item {
        padding: 26px 10px;
        border-bottom: 1px dashed #999999;
        .text {
          margin-top: -19px;
          float: left;
          width: 500px;
          color: #666666;
          line-height: 36px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.3s;
          &:hover {
            color: #6EB400;
          }
        }
      }
    }
  }
}
.pagi-box {
    text-align: center;
    padding: 30px 0 30px;
}
</style>
